//React
import React, { useState } from 'react'

//Gatsby
import { Link } from 'gatsby'

//Assets
import Logo from '../../images/svg/piconext-logo-04.svg'

const Nav = () => {
  const [openContext, setOpenContext] = useState(false)
  return (
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <Link
          class="navbar-brand"
          aria-label="PicoNext Home"
          activeClassName="navbar-brand"
          to={process.env.MARKETING_DOMAIN}
        >
          <Logo />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          // data-toggle="collapse"
          // data-target="#navbarText"
          // aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenContext(!openContext)}
        >
          <i class="navbar-toggler-icon"></i>
        </button>
        <div
          class={`navbar-main navbar-collapse ${
            openContext ? '' : 'collapse '
          }`}
          id="navbarText"
        >
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a
                href={`${process.env.MARKETING_DOMAIN}platform/`}
                class="nav-link"
                activeClassName="nav-link active"
              >
                Platform
              </a>
            </li>
            <li class="nav-item">
              <a
                href={`${process.env.MARKETING_DOMAIN}solutions/`}
                class="nav-link"
                activeClassName="nav-link active"
              >
                Solutions
              </a>
            </li>
            <li class="nav-item">
              <a
                href={`${process.env.MARKETING_DOMAIN}industry/`}
                class="nav-link"
                activeClassName="nav-link active"
              >
                Industries
              </a>
            </li>
            <li class="nav-item">
              <a
                href={`${process.env.MARKETING_DOMAIN}pricing/`}
                class="nav-link"
                activeClassName="nav-link active"
              >
                Pricing
              </a>
            </li>

            <li class="nav-item">
              <a
                href={`${process.env.MARKETING_DOMAIN}resources/`}
                class="nav-link"
                activeClassName="nav-link active"
              >
                Resources
              </a>
            </li>

            {/* <li class="nav-item">
              <a
                href={`${process.env.MARKETING_DOMAIN}about/pricing/`}
                class="nav-link"
                activeClassName="nav-link active"
              >
                Pricing
              </a>
            </li> */}
            <li class="nav-item">
              <Link
                to="/"
                class="nav-link"
                // target="_blank"
                activeClassName="nav-link active"
                // rel="noopener noreferrer"
              >
                Support
              </Link>
            </li>
          </ul>

          <div class="navbar-text ml-auto d-flex flex-row align-items-center justify-content-center">
            {/* <a
              href={`${process.env.MARKETING_DOMAIN}request-consultation/`}
              class="contact-link me-1 text-center border-right-1-gray-4"
              activeClassName="contact-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a> */}
            <a
              href={process.env.CONSOLE_DOMAIN}
              class="console-link me-1 text-center"
              activeClassName="console-link me-2 text-center active"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign in
            </a>
            <a
              href={`${process.env.MARKETING_DOMAIN}demo/`}
              class="demo-link"
              activeClassName="demo-link active"
            >
              <div class="btn btn-primary btn-cta">See demo</div>
            </a>
            {/* <a
              href={`${process.env.MARKETING_DOMAIN}start/`}
              class="nav-link"
              activeClassName="nav-link active"
              // target="_blank"
              // rel="noopener noreferrer"
            >
              <div class="btn btn-primary btn-cta">Free trial</div>
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
