//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

export default class Nav extends React.Component {
  render() {
    return (
      <nav class="navbar navbar-sub navbar-expand-lg bg-blue-6 border-top-1-blue-5 border-bottom-1-blue-5">
        <div class="container">
          <div
            class="d-flex flex-row align-items-center justify-content-center"
            id="navbarChallenge"
          >
            {/* <div
              class="card-title-solo black-3 text-right pr-4"
              style={{ width: "160px" }}
            >
              Challenges
            </div> */}
            <ul class="navbar-nav mr-auto">
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/pulse-surveys/"
                >
                  Pulse surveys
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/content-marketing/"
                >
                  Content marketing
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/offers-promotions/"
                >
                  Offers &amp; promotions
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/safety-compliance/"
                >
                  Safety reporting
                </Link>
              </li>

              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/review-management/"
                >
                  Review management
                </Link>
              </li>
              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/employee-training/"
                >
                  Employee training
                </Link>
              </li>

              <li class="nav-item pr-3">
                <Link
                  class="nav-link black-3"
                  activeClassName="nav-link dkblue-1 active"
                  to="/platform/lead-generation/"
                >
                  Lead generation
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  }
}
