//React
import React from 'react'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const PreFooter = (props) => (
  <StaticQuery
    query={graphql`
      query {
        phoneComposite: file(
          relativePath: {
            eq: "cta/publisher/storylava-publisher-composite.png"
          }
        ) {
          childImageSharp {
            fixed(width: 228, height: 245, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        <div class="jumbotron jumbotron-action bg-sky-6 py-4 py-lg-0 border-top-1-sky-1">
          <div class="container">
            <div class="row d-flex justify-content-center justify-content-lg-end">
              <div class="col-md-7 align-self-center text-center text-lg-right py-lg-0">
                <h3 style={{ position: 'relative', zIndex: 1 }}>
                  {props.title ? props.title : 'Drive your business'}
                </h3>
                <h4 class="display-4 black-4">
                  {props.context === 'challenge' ? (
                    <div>
                      Help your employees drive results through resilience
                    </div>
                  ) : null}
                  {!props.context ? (
                    <div>
                      Equip your teams to quickly create content that drives
                      your business forward
                    </div>
                  ) : null}
                </h4>

                {props.context === 'covid' ? (
                  <Link
                    to="/start-conversation/"
                    class="btn btn-primary btn-cta"
                    activeClassName="btn btn-primary btn-cta"
                  >
                    <div>Let's connect</div>
                  </Link>
                ) : (
                  <Link
                    to="/request-demo/"
                    class="btn btn-primary btn-cta"
                    activeClassName="btn btn-primary btn-cta"
                  >
                    <div>
                      Request demo <FaArrowRight class="btn-arrow" />
                    </div>
                  </Link>
                )}
              </div>
              <div class="col-md-3 align-self-center text-center text-lg-right d-none d-lg-block mt-3 mt-lg-1 mr-3">
                {props.context === 'brand' || !props.context ? (
                  <Img
                    alt={
                      props.title ? props.title : "Let's engage your customers"
                    }
                    style={{ display: 'block', margin: 'auto' }}
                    fixed={data.phoneComposite.childImageSharp.fixed}
                  />
                ) : null}
                {props.context === 'challenge' ? (
                  <Img
                    alt={props.title ? props.title : "Let's start a challenge"}
                    style={{ display: 'block', margin: 'auto' }}
                    fixed={data.phoneCompositeChallenge.childImageSharp.fixed}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    )}
  />
)

export default PreFooter
